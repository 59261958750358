import { Box, Grid, Typography } from '@mui/material';
import { take } from 'lodash';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { useProductsContext, useSessionContext } from 'src/common';
import { useSessionService } from 'src/services';

interface ICreateRequest {
  children: React.ReactNode;
}

export const CreateRequest = ({ children }: ICreateRequest) => {
  const { pendingProducts, setSelectedProducts } = useProductsContext();
  const isRequestPending = pendingProducts.length > 0;
  const { getSessionById } = useSessionService();
  const { sessionId, maxProducts, setMaxProducts } = useSessionContext();
  const { enqueueSnackbar } = useSnackbar();

  const handleSessionCheck = async () => {
    const updateMaxLots = await getSessionById(sessionId);
    const newLots = updateMaxLots.maxLots;
    setSelectedProducts((currentSelectedProducts) =>
      take(currentSelectedProducts ?? [], newLots)
    );
    if (newLots < maxProducts) {
      enqueueSnackbar(
        'Your max products has been reduced and some selected products may be removed.',
        {
          variant: 'warning'
        }
      );
    }
    setMaxProducts(newLots);
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      sx={{ height: '100%' }}
    >
      <Grid item xs={4}>
        <Box display="flex" flexDirection="column" alignItems="stretch">
          <Typography sx={{ fontSize: '1.4rem' }}>
            {isRequestPending ? 'View product catalogue' : 'Create Request'}
          </Typography>

          <Typography sx={{ fontSize: '1.05rem' }}>
            {isRequestPending
              ? 'View the product catalogue while you wait'
              : 'Create a request to view/return products'}
          </Typography>

          <Box mt={3}>
            <Link
              to={'/request-flow'}
              style={{ textDecoration: 'none' }}
              onClick={handleSessionCheck}
            >
              {children}
            </Link>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
