import { createContext, FC, useContext } from 'react';
import * as msal from 'msal';
import jwtDecode from 'jwt-decode';
import { useEnv } from '../env-context-provider/EnvContextProvider';
import { getUnixTime } from 'date-fns';
import { storageKeys } from 'src/lib/constants/storage-keys';
import { useLocalStorage } from 'src/hooks/use-local-storage/useLocalStorage';
import { useAuthenticationService } from 'src/services/authentication-service';

const AuthenticationContext = createContext<
  IAuthenticationContextProvider | undefined
>(undefined);

interface IDecodedJwt {
  name: string;
  preferred_username: string;
  exp: string;
}

interface IDecodedRoleJwt {
  [record: string]: string;
}
interface IAuthResponseIdToken {
  rawIdToken: string;
}
const loginRequest = {
  scopes: ['openid', 'profile', 'User.Read']
};
interface IAuthenticationContextProvider {
  username: string;
  signIn: () => void;
  canRefresh: () => boolean;
  refreshAccessToken: () => Promise<void>;
  isLoggedIn: (refresh: boolean) => Promise<boolean>;
  getAccessToken: (azureToken: string | null) => void;
  refreshAzureToken: () => void;
  clearAllStorage: () => void;
  getRefreshExpiry: () => number;
  getToken: () => string;
  validateRole: (permission?: string) => boolean;
}
export const AuthenticationContextProvider: FC = ({ children }) => {
  const { azureClientId, azureAuthority, azureRedirectUri, jwtScopesKey } =
    useEnv();
  const { authenticate } = useAuthenticationService();

  const msalConfig: msal.Configuration = {
    auth: {
      clientId: azureClientId,
      authority: azureAuthority,
      redirectUri: azureRedirectUri
    },
    cache: {
      cacheLocation: 'localStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false // Set this to 'true' if you are having issues on IE11 or Edge
    }
  };
  const [azureToken, setAzureToken, removeAzureToken] = useLocalStorage<string>(
    storageKeys.AZURE_TOKEN,
    ''
  );
  const [
    azureTokenExpiresAt,
    setAzureTokenExpiresAt,
    removeAzureTokenExpiresAt
  ] = useLocalStorage<string>(storageKeys.AZURE_TOKEN_EXPIRES_AT, '');
  const [accessToken, setAccessToken, removeAccessToken] =
    useLocalStorage<string>(storageKeys.ACCESS_TOKEN, '');
  const [
    accessTokenExpiresAt,
    setAccessTokenExpiresAt,
    removeAccessTokenExpiresAt
  ] = useLocalStorage<string>(storageKeys.ACCESS_TOKEN_EXPIRES_AT, '');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [username, setUsername] = useLocalStorage<string>(
    storageKeys.USERNAME,
    ''
  );

  const azureRedirectCallback = async (
    authErr: msal.AuthError,
    response?: msal.AuthResponse
  ) => {
    if (authErr) {
      // TODO: Handle auth errors
    } else {
      if (response) {
        const authResponseIdToken: IAuthResponseIdToken = response.idToken;
        await getAccessToken(authResponseIdToken.rawIdToken);
      }
    }
  };

  const isLoggedIn = async (refresh = true) => {
    if (accessToken && +accessTokenExpiresAt > getUnixTime(new Date())) {
      return true;
    }
    if (refresh && canRefresh()) {
      await refreshAccessToken();
      return true;
    }
    return false;
  };

  const canRefresh = () => {
    if (!accessToken || !accessTokenExpiresAt) {
      return false;
    }

    if (+accessTokenExpiresAt > getUnixTime(new Date())) {
      return false;
    }

    return true;
  };

  const getAccessToken = async (azureToken: string | null) => {
    if (azureToken) {
      const decodedJwt = jwtDecode<IDecodedJwt>(azureToken);

      setUsername(decodedJwt.name);
      setAzureToken(azureToken);
      setAzureTokenExpiresAt(decodedJwt.exp);
      try {
        const authenticateResponse = await authenticate(azureToken, 'azure');

        setAccessToken(authenticateResponse.token);
        setAccessTokenExpiresAt(String(authenticateResponse.expires));
      } catch (e) {
        // TODO: Handle auth errors
      }
    }
  };

  const refreshAccessToken = async () => {
    if (canRefresh()) {
      try {
        if (+azureTokenExpiresAt < getUnixTime(new Date())) {
          await refreshAzureToken();
        } else {
          await getAccessToken(azureToken);
        }
      } catch (error) {
        clearAllStorage();
        // TODO: Handle auth errors
      }
    }
  };

  const signIn = () => {
    clearAllStorage();
    const myMSALObj = new msal.UserAgentApplication(msalConfig);

    myMSALObj.handleRedirectCallback(azureRedirectCallback);
    myMSALObj.loginRedirect(loginRequest);
  };

  const refreshAzureToken = async () => {
    const { preferred_username } = jwtDecode<IDecodedJwt>(azureToken);
    const ssoRequest = {
      loginHint: preferred_username
    };
    const myMSALObj = new msal.UserAgentApplication(msalConfig);
    try {
      const response = await myMSALObj.ssoSilent(ssoRequest);

      await getAccessToken(response.idToken.rawIdToken);
    } catch (err) {
      await myMSALObj.loginPopup(ssoRequest);
    }
  };

  const clearAllStorage = () => {
    removeAzureToken();
    removeAzureTokenExpiresAt();
    removeAccessToken();
    removeAccessTokenExpiresAt();
  };

  const getToken = () => accessToken;

  const getRefreshExpiry = () => +azureTokenExpiresAt;

  const validateRole = (permission?: string) => {
    if (permission === undefined) {
      throw new Error('Permission was undefined');
    }

    if (!accessToken) {
      return false;
    }

    const payload = jwtDecode<IDecodedRoleJwt>(accessToken);
    const scopes: string = payload[`${jwtScopesKey}`];
    if (!scopes) {
      return false;
    }
    return scopes.includes(permission);
  };

  return (
    <AuthenticationContext.Provider
      value={{
        signIn,
        canRefresh,
        refreshAccessToken,
        isLoggedIn,
        getAccessToken,
        refreshAzureToken,
        clearAllStorage,
        getRefreshExpiry,
        getToken,
        validateRole,
        username
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuthenticationContext = () => {
  const authenticationContext = useContext(AuthenticationContext);

  if (!authenticationContext) {
    throw new Error(
      'Authentication Context cannot be used outside of Authentication Context Provider'
    );
  }

  return authenticationContext;
};
