import { createContext, FC, useContext } from 'react';
import { useSessionStorage } from 'src/hooks/use-session-storage/useSessionStorage';

type SessionProviderType = {
  roomId: number;
  setRoomId: (room: number) => void;
  locationId: number;
  setLocationId: (location: number) => void;
  sessionId: string;
  setSessionId: (session: string) => void;
  location: string;
  setLocation: (location: string) => void;
  maxProducts: number;
  setMaxProducts: (max: number) => void;
  clearLocalStorage: () => void;
  customerId: string;
  setCustomerId: (customerId: string) => void;
};

const SessionProviderContext = createContext<SessionProviderType | undefined>(
  undefined
);

export const SessionProvider: FC = ({ children }) => {
  const [sessionId, setSessionId, removeSessionId] = useSessionStorage<string>(
    'sessionId',
    ''
  );

  const [location, setLocation, removeLocation] = useSessionStorage<string>(
    'location',
    ''
  );

  const [roomId, setRoomId] = useSessionStorage<number>('roomId', 0);

  const [locationId, setLocationId] = useSessionStorage<number>(
    'locationId',
    0
  );

  const [maxProducts, setMaxProducts] = useSessionStorage<number>(
    'maxProducts',
    0
  );

  const clearLocalStorage = () => {
    setSessionId('');
    removeSessionId();
    removeLocation();
  };

  const [customerId, setCustomerId] = useSessionStorage<string>(
    'customerId',
    ''
  );

  return (
    <SessionProviderContext.Provider
      value={{
        roomId,
        setRoomId,
        locationId,
        setLocationId,
        sessionId,
        setSessionId,
        location,
        setLocation,
        maxProducts,
        setMaxProducts,
        clearLocalStorage,
        customerId,
        setCustomerId
      }}
    >
      {children}
    </SessionProviderContext.Provider>
  );
};

export const useSessionContext = () => {
  const context = useContext(SessionProviderContext);

  if (!context) {
    throw new Error(
      'Session Provider Context cannot be used outside of Session Context Provider'
    );
  }

  return context;
};
